import React from 'react';
import Layout from '../components/layout';
import Footer from '../components/footer';
import HelmetEx from '../components/helmet-ex';
import Banner from '../components/banner';
import Paginate from '../components/paginate';
import OpenPositionFeed from '../components/open-positions-feed'

const OpenPositionList = ({
    pageContext
}) => {
    const { currentPage, numPages, positions } = pageContext;
    const prevPage =
        currentPage - 1 === 1
            ? '/careers/open-positions'
            : `/careers/open-positions/${(currentPage - 1).toString()}`;
    const nextPage = `/careers/open-positions/${(currentPage + 1).toString()}`;

    return <Layout>
        <div className="open-positions-list">
            <HelmetEx>
                <div metadata="title">Polymaths | Open position</div>
                <div metadata="path">/careers/open-positions</div>
                <div metadata="canonicalurl">/careers/open-positions</div>
                <div metadata="description">
                    Apply to available positions at polymaths
                </div>
            </HelmetEx>
            <Banner name="Open positions" />
            <div className="container pt-4">
                <OpenPositionFeed positions={positions} />
                <div className="text-center d-flex justify-content-center">
                    <Paginate
                        nextPage={nextPage}
                        currentPage={currentPage}
                        baseUrl="careers/open-positions"
                        prevPage={prevPage}
                        allPages={numPages}
                    />
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
};

export default OpenPositionList;
